<template>
  <div class="dropdown-wrapper" :class="getUniqueClass">
    <div class="label" v-if="label">
      <p class="subtitle-2">{{ label }}</p>
      <slot name="append-label" />
    </div>
    <v-select
      class="dropdown"
      :class="label && 'has-label'"
      :style="width && `width: ${widthStyle}`"
      v-bind="$attrs"
      v-on="$listeners"
      ref="select"
      outlined
      append-icon="mdi-chevron-down"
      :placeholder="placeholder"
      :menu-props="{
        contentClass: getMenuClass,
        maxHeight: menuHeight,
        offsetOverflow: true,
        offsetY: true,
        positionY: scroll.offset || -verticalObstruction,
        zIndex: menuZIndex,
      }"
      :multiple="multiple"
      :value="value"
    >
      <template v-if="multiple" v-slot:item="{ item, attrs }">
        <div class="multi-select-dropdown-item">
          <checkbox
            v-bind="attrs"
            :value="
              (value &&
                (value.includes(item.value) || value.includes('ALL'))) ||
              false
            "
          />
          <p class="subtitle-1">{{ item.text }}</p>
        </div>
      </template>
      <template v-if="multiple" v-slot:selection="{ item }">
        <tag>{{ item.text }}</tag>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </v-select>
    <p v-if="warning" class="body-2 warning">{{ warning }}</p>
  </div>
</template>

<script>
/**
 * @fileoverview Common component for dropdowns
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import {
  getScrollParent,
  observeMenu,
  getMenuClass,
  getUniqueClass,
} from "../utils/menu-helpers";

import checkbox from "./checkbox";
import tag from "./tag";

export default {
  name: "Dropdown",
  components: {
    checkbox,
    tag,
  },
  data() {
    return {
      intersectionObserver: null,
      mutationObserver: null,
      scroll: {
        parent: null,
        start: null,
        offset: null,
      },
      verticalObstruction: 0,
      menuHeight: "auto",
    };
  },
  props: {
    label: {
      type: String,
    },
    menuZIndex: {
      type: Number,
      default: 5,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select...",
    },
    value: {
      type: [Array, Number, String],
    },
    warning: {
      type: String,
    },
    width: {
      type: String,
    },
  },
  methods: {
    getMaxMenuHeight() {
      if (!this.$refs.select.$el) return;

      const scrollParent = this.scroll.parent || document.body;
      const selectMenu = this.$refs.select.$el;

      const height = scrollParent.getBoundingClientRect().height;

      const topSpacing = selectMenu.getBoundingClientRect().top - scrollParent.getBoundingClientRect().top;
      const bottomSpacing = scrollParent.getBoundingClientRect().bottom - selectMenu.getBoundingClientRect().bottom;

      const minSpacing = Math.min(topSpacing, bottomSpacing);

      return height - minSpacing - 64;
    },
    setMaxMenuHeight() {
      this.menuHeight = this.getMaxMenuHeight();
    },
    onScroll() {
      this.scroll.offset = this.scroll.start - this.scroll.parent.scrollTop;
      this.setMaxMenuHeight();
    },
    observeMenu() {
      const {
        mutationObserver,
        intersectionObserver,
        verticalObstruction,
        menuHeight,
      } = observeMenu({
        menuClass: this.getMenuClass,
        menuHeight: this.menuHeight,
      });
      this.mutationObserver = mutationObserver;
      this.intersectionObserver = intersectionObserver;
      this.verticalObstruction = verticalObstruction;
      this.menuHeight =
        typeof menuHeight === "string"
          ? this.getMaxMenuHeight()
          : Math.min(menuHeight, this.getMaxMenuHeight());
    },
    disconnectObservers() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },
    getScrollParent() {
      return getScrollParent({ wrapperClass: this.getUniqueClass });
    },
    validate() {
      return this.$refs.select.validate();
    },
  },
  computed: {
    getMenuClass() {
      return getMenuClass();
    },
    getUniqueClass() {
      // need a random id for each instance of this component
      return getUniqueClass();
    },
    widthStyle() {
      const outerSpacing = this.prependInnerIcon ? 108 : 68;
      return `calc(${this.width || 0} + ${outerSpacing}px)`;
    },
  },
  mounted() {
    this.setMaxMenuHeight();
    window.addEventListener("resize", this.setMaxMenuHeight);
    // watch workaround for tracking $refs changes
    this.$watch(
      () => {
        return this.$refs.select.isMenuActive;
      },
      async (isMenuActive) => {
        if (isMenuActive) {
          // calculate position on screen to account for scrolling
          this.scroll.parent = this.getScrollParent();
          this.scroll.parent.addEventListener("scroll", this.onScroll);
          this.scroll.start = this.scroll.parent.scrollTop;

          // check for menu being obscured by other elements
          this.observeMenu();
        } else {
          this.scroll.parent.removeEventListener("scroll", this.onScroll);
          this.scroll = {
            parent: null,
            start: null,
            offset: null,
          };
          this.disconnectObservers();
          this.verticalObstruction = 0;
          this.setMaxMenuHeight();
        }
      }
    );
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMaxMenuHeight);
    this.disconnectObservers();
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 200px;
  top: 5px;
  &::v-deep {
    .v-input__slot {
      min-height: inherit;
      margin-bottom: 0;
      & input::placeholder {
        color: $qvo-color-grey-600;
        // subtitle-1 styles
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
      }
    }
    .v-text-field__details {
      margin-bottom: 0;
      min-height: unset;
      .v-messages {
        min-height: unset;
      }
    }
    .v-select__slot {
      height: 42px;
      min-height: 37px;
      top: -4px;
      .v-select__selections {
        flex-wrap: unset;
        input {
          cursor: pointer;
        }
        .v-select__selection {
          width: 100%;
          max-width: unset;
        }
      }
    }
    .v-label {
      top: 7px;
    }
    .v-input__prepend-inner {
      margin-top: 5px; // the built-in vuetify styles add a 17px top margin
      .v-icon {
        z-index: 1;
      }
    }
    .v-input__append-inner {
      margin-top: 9px; // the built-in vuetify styles add a 17px top margin
    }
    .v-input__prepend-inner {
      padding-right: 16px;
    }
    .v-input__append-inner {
      padding-left: 16px;
    }
    .v-input__append-outer {
      margin-top: 4px;
    }
    fieldset {
      background: $qvo-color-grey-000;
      border-color: $qvo-color-grey-300;
      border-radius: 8px;
      height: 42px;
      min-height: 42px;
      legend {
        display: none;
      }
    }
  }
}
.v-select--is-menu-active {
  &::v-deep {
    fieldset {
      background: $qvo-color-blue-100;
    }
  }
}
.v-input--is-focused {
  &::v-deep {
    fieldset {
      border-width: 1px;
    }
  }
}

.v-input--is-disabled {
  &::v-deep {
    .v-label p {
      color: $qvo-color-grey-300;
    }
  }
}

.v-select--is-multi {
  &::v-deep {
    fieldset {
      height: unset;
    }
    .v-select__slot {
      height: unset;
      top: -2px;
      .v-select__selections {
        flex-wrap: wrap;
        padding: 0;
        gap: 8px;
      }
      .v-chip {
        margin: 0;
        max-width: 10em;
        overflow: auto;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .v-input__append-inner {
        margin-top: 7px;
      }
    }
  }
}
p {
  margin-bottom: 0;
}
.label {
  display: flex;
  align-items: center;
  gap: $qvo-spacing-8;
  margin-bottom: $input-label-spacing;
}
.warning {
  color: $qvo-color-warning;
}
</style>

// Generally should avoid non-scoped styles, but this menu is attached to the DOM at a higher level
<style lang="scss">
.v-application .v-menu__content {
  border-radius: 8px;
  box-shadow: $qvo-shadow-2;
  max-height: calc(100vh - 24px);
  .v-list {
    padding: 0;
    .multi-select-dropdown-item {
      display: flex;
      padding: 9px 12px 8px 12px;
      p {
        max-width: 800px;
      }
    }
  }
}
</style>