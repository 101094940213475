var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-wrapper",class:_vm.getUniqueClass},[(_vm.label)?_c('div',{staticClass:"label"},[_c('p',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.label))]),_vm._t("append-label")],2):_vm._e(),_c('v-select',_vm._g(_vm._b({ref:"select",staticClass:"dropdown",class:_vm.label && 'has-label',style:(_vm.width && `width: ${_vm.widthStyle}`),attrs:{"outlined":"","append-icon":"mdi-chevron-down","placeholder":_vm.placeholder,"menu-props":{
      contentClass: _vm.getMenuClass,
      maxHeight: _vm.menuHeight,
      offsetOverflow: true,
      offsetY: true,
      positionY: _vm.scroll.offset || -_vm.verticalObstruction,
      zIndex: _vm.menuZIndex,
    },"multiple":_vm.multiple,"value":_vm.value},scopedSlots:_vm._u([(_vm.multiple)?{key:"item",fn:function({ item, attrs }){return [_c('div',{staticClass:"multi-select-dropdown-item"},[_c('checkbox',_vm._b({attrs:{"value":(_vm.value &&
              (_vm.value.includes(item.value) || _vm.value.includes('ALL'))) ||
            false}},'checkbox',attrs,false)),_c('p',{staticClass:"subtitle-1"},[_vm._v(_vm._s(item.text))])],1)]}}:null,(_vm.multiple)?{key:"selection",fn:function({ item }){return [_c('tag',[_vm._v(_vm._s(item.text))])]}}:null,_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2),(_vm.warning)?_c('p',{staticClass:"body-2 warning"},[_vm._v(_vm._s(_vm.warning))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }